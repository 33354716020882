import PropTypes from 'prop-types';
import prismicLinkShape from './prismicLinkShape';
import prismicImageShape from 'shapes/prismicImageShape';

const { shape, string } = PropTypes;

export default shape({
  type: string.isRequired,
  label: string,
  primary: shape({
    youtubeImage: prismicImageShape,
    spotifyImage: prismicImageShape,
    applePodcastImage: prismicImageShape,
    youtubeLink: prismicLinkShape,
    spotifyLink: prismicLinkShape,
    applePodcastLink: prismicLinkShape,
  }),
});
