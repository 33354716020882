import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { down, up } from 'styled-breakpoints';
import PrismicLink from 'components/PrismicLink';
import { graphql, Link, StaticQuery } from 'gatsby';
import { withPreview } from 'gatsby-source-prismic-graphql';
import { isAndroid, isIOS } from 'react-device-detect';
import { ifProp, prop, theme } from 'styled-tools';
import styled, { css } from 'styled-components/macro';
import Button from 'components/Button';
import BaseCol from 'react-bootstrap/Col';
import RichText from 'components/RichText';
import AppDownload from 'components/AppDownload';
import Container from 'react-bootstrap/Container';
import BaseJumbotron from 'react-bootstrap/Jumbotron';
import prismicLinkShape from 'shapes/prismicLinkShape';
import prismicImageShape from 'shapes/prismicImageShape';
import prismicRichTextShape from 'shapes/prismicRichTextShape';
import appDownloadLinksDataShape from 'shapes/appDownloadLinksDataShape';
import { getImgAlt } from 'utils/functions';
import mediadDownloadLinksDataShape from 'shapes/mediadDownloadLinksDataShape';
import MediaDownload from 'components/MediaDownload';

const PageHeader = styled.div`
  padding: 3rem 0;
`;

const Jumbotron = styled(BaseJumbotron).attrs((props) => ({
  className: 'bg-dark',
}))`
  color: ${theme('colors.white')};

  ${down('sm')} {
    margin-bottom: 0;
  }

  ${ifProp(
    'img',
    css`
    align-items: center;
    background-image: url("${prop('mobileImg')}");
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: right;
    display: flex;
    min-height: 18rem;
    padding-top: 18rem;

    ${up('sm')} {
      background-image: url("${prop('img')}");
      min-height: 24rem;
      padding-top: 4rem;
    }

    ${up('md')} {
      min-height: 30rem;
    }

    ${up('lg')} {
      min-height: 34rem;
    }
  `
  )}

  ${ifProp(
    ['img', { imgGradient: true }],
    css`
      ${up('sm')} {
        background-image: linear-gradient(300deg, rgba(0,0,0,0.38) 0%, #000000 92%), url("${prop(
          'img'
        )}");
    }
    `
  )}

  ${ifProp(
    ['img', { mobileImgGradient: true }],
    css`
    ${down('sm')} {
      background-image: linear-gradient(183deg, rgba(0,0,0,0.38) 0%, #000000 92%), url("${prop(
        'mobileImg'
      )}");
    }
    `
  )}

  h1 {
    color: ${theme('colors.primary')};

    ${down('md')} {
      font-size: 2.25rem;
    }

    ${down('xs')} {
      font-size: 2.5rem;
    }
  }
`;

const Col = styled(BaseCol)`
  max-width: 100%;

  ${up('sm')} {
    max-width: 59%;
  }

  ${down('sm')} {
    padding: 0;
  }
`;

const AppDownloadLinks = styled.div`
  display: flex;

  a {
    ${up('xs')} {
      margin-right: 2rem;
    }
  }
`;

const MediaDownloadLinks = styled.div`
  display: flex;
  flex-direction: column;

  ${up('xl')} {
    align-items: center;
    flex-direction: row;
  }
`;

const appDownloadLinksQuery = graphql`
  query MastheadAppDownloadLinksQuery {
    prismic {
      allSocial_medias {
        edges {
          node {
            ...AppDownloadLinksFragment
            ...MediaDownloadLinksFragment
          }
        }
      }
    }
  }
`;

const propTypes = {
  data: PropTypes.shape({
    mastImage: prismicImageShape,
    mastHeading: prismicRichTextShape,
    mastBody: prismicRichTextShape,
    mastCtaText: PropTypes.string,
    mastCtaLink: prismicLinkShape,
    pageTitle: prismicRichTextShape,
  }).isRequired,
  appDownloadLinksData: appDownloadLinksDataShape,
  mediaDownloadLinksData: mediadDownloadLinksDataShape,
};

function Masthead({
  data: {
    mastImage,
    mastGradient,
    mastMobileGradient,
    appButtonDownloads,
    mastHeading,
    mastBody,
    mastCtaText,
    mastCtaLink,
    mediaButtonDownloads,
    mastheadHeading,
    mastheadBody,
    mastheadCtaText,
    mastheadCtaLink,
    pageTitle,
  },
  appDownloadLinksData,
}) {
  const appDownloadLinks = get(
    appDownloadLinksData,
    'prismic.allSocial_medias.edges[0].node',
    {}
  );

  const heading = mastHeading || pageTitle;
  const {
    appStoreImage,
    appStoreLink,
    googlePlayLink,
    googlePlayImage,
    youtubeLink,
    spotifyLink,
    applePodcastLink,
    youtubeImage,
    spotifyImage,
    applePodcastImage,
  } = appDownloadLinks;

  if (!mastImage) {
    return (
      <PageHeader>
        <Container>
          <RichText render={heading} />
        </Container>
      </PageHeader>
    );
  }

  const isDesktop = !isIOS && !isAndroid;

  const mainImageUrl = get(mastImage, 'url');
  const mobileImageUrl = get(mastImage, 'mobile.url', mainImageUrl);

  return (
    <Jumbotron
      fluid
      alt={getImgAlt(mastImage)}
      img={mainImageUrl}
      mobileImg={mobileImageUrl}
      imgGradient={mastGradient}
      mobileImgGradient={mastMobileGradient}
    >
      <Container className='h-100'>
        <Col
          xs={12}
          md={7}
          className='d-flex flex-column justify-content-center h-100'
        >
          {heading && <RichText render={heading} />}
          {mastBody && <RichText render={mastBody} />}
          {mastCtaText && mastCtaLink && (
            <PrismicLink
              link={mastCtaLink}
              internalAs={Button}
              internalProps={{ forwardedAs: Link }}
              externalAs={Button}
              externalProps={{ target: '_blank' }}
              variant={'primary'}
            >
              {mastCtaText}
            </PrismicLink>
          )}
          {appButtonDownloads && (
            <AppDownloadLinks>
              {(isIOS || isDesktop) && appStoreLink && appStoreImage && (
                <AppDownload
                  appLogo={appStoreImage}
                  downloadLink={appStoreLink}
                />
              )}
              {(isAndroid || isDesktop) &&
                googlePlayLink &&
                googlePlayImage && (
                  <AppDownload
                    appLogo={googlePlayImage}
                    downloadLink={googlePlayLink}
                  />
                )}
            </AppDownloadLinks>
          )}
          {mediaButtonDownloads && (
            <MediaDownloadLinks>
              {youtubeLink && youtubeImage && (
                <MediaDownload
                  mediaLogo={youtubeImage}
                  mediaLink={youtubeLink}
                />
              )}
              {spotifyLink && spotifyImage && (
                <MediaDownload
                  mediaLogo={spotifyImage}
                  mediaLink={spotifyLink}
                />
              )}
              {applePodcastLink && applePodcastImage && (
                <MediaDownload
                  mediaLogo={applePodcastImage}
                  mediaLink={applePodcastLink}
                />
              )}
            </MediaDownloadLinks>
          )}
        </Col>
      </Container>
    </Jumbotron>
  );
}

Masthead.propTypes = propTypes;

export default (props) => (
  <StaticQuery
    query={appDownloadLinksQuery}
    render={withPreview(
      (data) => (
        <Masthead appDownloadLinksData={data} {...props} />
      ),
      appDownloadLinksQuery
    )}
  />
);
