import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import Layout from '../components/layout';
import SEO from 'components/SEO';
import Masthead from 'components/Masthead';
import Slices from 'components/Slices';
import { extractPageStyles, extractSocialMeta } from 'utils/fieldExtractors';

function IndexPage({ data, ...props }) {
  const doc = get(data, 'prismic.allHomepages.edges[0].node', null);
  if (!doc) {
    return null;
  }
  const { _meta, seoTitle, seoMetaDescription, body } = doc;

  const pageName = get(doc, 'pageTitle[0].text', '');

  return (
    <Layout pageStyles={extractPageStyles(doc)}>
      <SEO
        title={seoTitle || pageName}
        description={seoMetaDescription}
        lang={_meta}
        socialMeta={extractSocialMeta(doc)}
      />
      <Masthead data={doc} />
      <Slices slices={body} {...props} />
    </Layout>
  );
}

export const query = graphql`
  query HomepageQuery {
    prismic {
      allHomepages(uid: "homepage") {
        edges {
          node {
            pageTitle: page_title
            seoTitle: seo_title
            seoMetaDescription: seo_meta_description
            ogTitle: og_title
            ogImage: og_image
            ogDescription: og_description
            twitterCard: twitter_card
            twitterImage: twitter_image
            twitterTitle: twitter_title
            twitterDescription: twitter_description
            appButtonDownloads: app_button_downloads
            mastBody: masthead_body
            mastHeading: masthead_heading
            mastImage: masthead_image
            mastGradient: masthead_image_gradient
            mastMobileGradient: masthead_mobile_image_gradient
            mastCtaText: masthead_cta_text
            mastCtaLink: masthead_cta_link {
              ...LinkFragment
            }
            mediaButtonDownloads: media_button_downloads
            pageBackgroundColor: page_background_color
            pageBodyTextColor: page_body_text_color
            body {
              ...LPBasicCtaFragment
              ...LPBlockStyleFragment
              ...LPCarouselFragment
              ...LPCenteredFeatureFragment
              ...LPCtaBannerFragment
              ...LPDifferentiatorFragment
              ...LPFeaturedImageLeftRightFragment
              ...LPFeaturedOnFragment
              ...LPLearnBlockFragment
              ...LPMultiBlockFragment
              ...LPReviewsFragment
              ...LPTableFragment
              ...LPTeamBioFragment
              ...LPTextBlockFragment
              ...LPTwoBlockFragment
            }
            _meta {
              lang
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
